.StudentResult{
    /* flex: 1;
    padding: 20px;
    overflow-y: auto;*/
    padding-left: 2.5%; 
    background-color: #f2f5fe;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
} 
.Resultback{
    margin-left: 3%;
    padding-left: 10px;
    margin-bottom: 10px;
}

.ProfileIfno{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.ResultHeading{
    background-color: #d2e1ff;
    flex: 1;
    overflow-y: auto;
    margin-left: 0.1%;
    display: flex;
    align-items: center;
    padding-left: 60px;

}

.headingdiv{
  width: 85%;
  line-height: 15px;

}

.ResultbackBtn{
    color: black;
    padding-left: 15px;
    position: relative;
    top:5px;
    background: none;
    border: none;
}

.ResultbackBtn:hover{
    color: #1c7287;
    transform: scale(1.1);
}

.resultsSection {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }
  
.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .raisecResultsCard {
    width: calc(20.333% - 20px);
    min-width: 250px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .startestbtn{
    background-color: #1FCC7D;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 7px;
    border: none;

  }

  .studentTemperamentList{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f6f8ff;
    padding-top: 20px;
    min-height: 100vh;
  }

  .studentTemperamentcards{
    width: 85%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .uppercard{
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .tempdescription{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 20px;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
    height: 85%;
  }

  .descriptioninnerbox{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    /* border: 1px solid #363636; */
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;

  }

  .tempprofileimg{
    border-radius: 10px;
    width: 250px;
    height: 87%;
  }

  .lowercard{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    padding: 20px;
    padding-left: 20px;
  }

  .card{
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-height: 180px;
  }
  
  .iconContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .resultIcon {
    margin: 0 5px;
  }
  
  .resultIcon img {
    width: 70px;
    height: 70px;
  }
  
  .ResultsUpper {
    margin-bottom: 5px;
  }
  
  .ResultsUpper p {
    margin: 2px 0;
  }
  
  .resultsLower {
    width: 100%;
  }
  
  .collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .expanded {
    display: block;
  }
  
  .readMoreBtn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 5px;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .readMoreBtn:hover {
    text-decoration: underline;
  }
  
  
  @media (max-width: 1024px) {
    .raisecResultsCard {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .raisecResultsCard {
      width: 100%;
    }

    .ResultHeading{
      padding-left: 0;

  }

    .ProfileIfno{
      flex-direction: column;
    }

    .ProfileIfno h2{
      color: n;
    }
    

    .headingdiv{
      line-height: 24px;
    
    }

    .studentTemperamentList{
      padding-bottom: 20px;
    }

    .uppercard{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }