.test-page {
  background-color: #f6f8ff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  width: 100%;
  padding-bottom: 2rem;

}

.login-form::-webkit-scrollbar {
  width: 0;
  display: none;
}

/* First Section */
.heading-section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.test-heading {
  text-align: center;
  font-size: 40px;
  padding: 10px;
  font-weight: 400;
  color: #294478;
}


.test-instructions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 40vh;
  height: auto;
  margin-bottom: 6rem;
  padding: 30px;
}

.test-instructions p {
  font-size: 16px;
  line-height: 24px;
}

.left-instructions {
  flex: 1;
  padding: 20px;
}

.left-instructions h2, .left-instructions h3 {
  margin-bottom: 10px;
}

.left-instructions h2{
  font-size: 34px;
}

.instructions {
  margin-bottom: 20px;
}

.start-test-button {
  padding: 8px 20px;
  width: 60%;
  border: none;
  background: linear-gradient(to right, #4a6daf, #253553);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  
}


.right-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.right-box-content {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  width: 60%;
  
}

.box-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

.underline {
  margin-bottom: 10px;
}

.test-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.test-item {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}


.ins{
  position: absolute;
  top: 0;
}

.back-text{
  cursor: pointer;
  color: #294478;
}

.back-text:hover{
  text-decoration: underline;
}


/* Third Section */
.mid-section {
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  gap: 10px;
  width: 80%;
}

.mid-heading {
  margin: 0;
}

.mid-paragraph {
  margin: 0;
  font-size: 20px;
}


.card-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card-section .card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  max-width: 350px;
  min-width: 150px;
}

.card-section .card h3{
  font-size: 20px;
  margin-bottom: 10px;
}


@media (max-width: 768px) {

  .mid-section {
    width: 90%;
    margin-bottom: 2rem;
  }

  .card-section {
    flex-direction: column;
    align-items: center;
  }

  
  .test-page {
    padding: 0;
    height: auto;
    padding-bottom: 2rem;
  
  }

  .test-instructions {
    margin-bottom: 2rem;
  }

}